import { createResource, createSignal, For, Match, on, onCleanup, onMount, Show, Switch } from "solid-js";

import socket from "./socket";
import AdminUsers from "./AdminUsers";
import { useAdminContext } from "./AdminContext";
import { useUserContext } from "./UserContext";
import { NavDropdown } from "./Components";

export default function Admin() {

    const [adminStore, setAdminStore] = useAdminContext();
    const [userStore, setUserStore] = useUserContext();

    const [activeTab, setActiveTab] = createSignal('Liste Etudiants');
    
    const tabClass = (tabName) => {
        return activeTab() === tabName ? 'nav-link active' : 'nav-link';
    }
    const handleTabClick = (tabName) => (event) => {
        setActiveTab(tabName);
        event.preventDefault();
    };

    return <>
        <Switch>
            <Match when={userStore.page === '/manage-users'}>
                <AdminUsers />
            </Match>
        </Switch>
    </>
}

export const AdminMenu = () => {

    const [ userStore, setUserStore, userActions] = useUserContext();
    
    return <NavDropdown label="Admin">
        <li><a
            onclick={() => { userActions.setPage('/manage-users'); }}
            class="dropdown-item" href="#">Manage Users</a></li>
      </NavDropdown>;
}