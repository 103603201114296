
import { createContext, useContext, onMount } from "solid-js";
import { createStore, produce } from "solid-js/store";
import socket from "./socket";

const UserContext = createContext();


// retrieve query params 'jwt'
const urlParams = new URLSearchParams(window.location.search);
const jwt = urlParams.get('jwt') || localStorage.getItem('jwt');
if(jwt) {
  localStorage.setItem('jwt', jwt);
}
if(urlParams.get('jwt')) {
  //remove jwt from url
  window.history.replaceState({}, document.title, "/");
}




export function UserContextProvider(props) {
  const [userStore, setUserStore] = createStore({
    projects: [],
    openedProject: {},
    page: '/',
    id: null,
    username: null,
    role: null,
    loadingUser: true,
    showAdvancedActions: false,
    lastSelectedStudent: null,
  });


  
  onMount(() => {
    console.log('Mounted UserContext');

    socket.on("connect", () => {
      console.log("connected");
      if(jwt) {
        socket.emit("authenticate", jwt);
      } 
      else {
        setUserStore({
          loadingUser: false,
        });
      }
    });


    socket.on("projects", (projects) => {
      setUserStore({projects});
    });

    socket.on("authenticated", ({id, username, role}) => {
        console.log('connected as', {id, username, role});
        setUserStore({id, username, role, loadingUser: false});
        if(localStorage.getItem('next')) {
          const next = localStorage.getItem('next');
          localStorage.removeItem('next');
          location.href = next;
        }
    });

    socket.on("unauthorized", (error) => {
        console.log({error});
        localStorage.removeItem('jwt');
        setUserStore({
          username: null,
          role: null,
          loadingUser: false,
        });
    });


    socket.on('opened-project', (data) => {
      setUserStore('openedProject', data.id, {connected: [], ...data});
    });
    socket.on('join-link', (data) => {
      setUserStore('openedProject', data.project_id, 'sharingLink', data.token);
    });
    socket.on('page-added', ({project_id, name, cells}) => {
      setUserStore('openedProject', project_id, 'pages', name, cells);
    });
    socket.on('page-removed', ({project_id, name}) => {
      setUserStore('openedProject', project_id, 'pages', produce(l => {
        delete l[name];
      }));
    });
    socket.on('page-renamed', ({project_id, oldName, newName}) => {
      setUserStore('openedProject', project_id, 'pages', produce(l => {
        l[newName] = l[oldName];
        delete l[oldName];
      }));
      if(oldName == userStore.lastSelectedStudent) {
        setUserStore('lastSelectedStudent', newName);
        actions.setPage(`/projects/${project_id}/${newName}`);
      }
    });
    socket.on('cell-updated', ({project_id, page, key, value}) => {
      setUserStore('openedProject', project_id, 'pages', page, key, 'value', value);
    });
    socket.on('cell-locked', ({project_id, page, key, lockedBy}) => {
      setUserStore('openedProject', project_id, 'pages', page, key, 'lockedBy', lockedBy);
    });
    socket.on('cell-unlocked', ({project_id, page, key}) => {
      setUserStore('openedProject', project_id, 'pages', page, key, 'lockedBy', null);
    });
    socket.on('cell-editable', ({project_id, page, key}) => {
      setUserStore('openedProject', project_id, 'pages', page, key, 'editable', true);
    });
    socket.on('user-connected', ({project_id, username}) => {
      if(project_id) {
        setUserStore('openedProject', project_id, 'connected', l => [...l, username]);
      }
    })
    socket.on('user-disconnected', ({project_id, username}) => {
      if(project_id) {
        setUserStore('openedProject', project_id, 'connected', l => l.filter(u => u !== username));
      }
    })

  });

  const actions = {
    createProject: (fields) => {
      console.log('createProject', fields);
    },
    setPage: (page, options = {}) => {
      const s = options.keepSearch ? location.search : '';
      console.log('setPage', page);
      history.pushState({}, '', page+s);
     // location.search = s;
      setUserStore({page});
    },
    lockCell(project_id, page, cell) {
      //console.log('lockCell', project_id, page, cell);
    },
    toggleShowAdvancedActions: () => {
      setUserStore('showAdvancedActions', (v) => !v);
    },
    setLastSelectedStudent: (student) => {
      setUserStore('lastSelectedStudent', student);
    }
  }

  return (
    <UserContext.Provider value={[userStore, setUserStore, actions]}>
      {props.children}
    </UserContext.Provider>
  );
}

export function useUserContext() { return useContext(UserContext); }
