import { For, createEffect, createMemo, createSignal } from "solid-js";
import { saveAs } from 'file-saver';

const _element_re = /\{([^\}]+)\}/igm;
// Formulas have the form "{element1} + {element2}/2"
// and we want to return an array of elements
function getElements(formula, cells) {
    let uniformMatch = formula.match(/uniform\(\/(\d+)\)/)
    if(uniformMatch) {
        return cells.map((c) => c.label);
    }
    const matches = [...formula.matchAll(_element_re)].map((m) => m[1]);
    return matches;
}

function evalFormula(subFormula, subElements, elementsValue) {

    let uniformMatch = subFormula.match(/uniform\(\/(\d+)\)/)
    if(uniformMatch) {
        let maxValue = parseInt(uniformMatch[1]);
        let note = 0;
        for(let subEl of subElements) {
            note += parseFloat(elementsValue[subEl] || 0) * 20.0/ maxValue;
        }
        note /= subElements.length;
        return Math.round(note * 100) / 100;
    }

    for(let subEl of subElements) {
        subFormula = subFormula.replaceAll('{'+subEl+'}', parseFloat(elementsValue[subEl] || 0));
    }
    return Math.round(Function("Date", `"use strict"; return ${subFormula};`)() * 100) / 100;
}

export default (props) => {
    
    const topElements = createMemo(() => {
        const formula = props.project.summary_template.split('\n').filter(l => l.trim().startsWith('note:'))[0].split(':')[1];
        return getElements(formula);
    });
    const notes = createMemo(() => {
        const formula = props.project.summary_template.split('\n').filter(l => l.trim().startsWith('note:'))[0].split(':')[1];
        const topElements = getElements(formula);
        const notes = {};
        for(const el of topElements)
        {
            const subFormula = props.project.student_template.filter((e) => e.name == el)[0].note;
            const subElements = getElements(subFormula, props.project.student_template.filter((e) => e.name == el)[0].cells);

            for(const student of Object.keys(props.project.pages))
            {
                const elementsValue = {};
                subElements.forEach((subEl) => {
                    elementsValue[subEl] = props.project.pages[student][el+':'+subEl+':note'].value;
                });
                for(const subEl of subElements)
                {
                    if(!notes[student]) notes[student] = {};
                    notes[student][el] = evalFormula(subFormula, subElements, elementsValue);
                }
            }
        }
        for(const student of Object.keys(props.project.pages))
        {
            const elementsValue = {};
            topElements.forEach((el) => {
                elementsValue[el] = notes[student][el];
            });
            for(const el of topElements)
            {
                notes[student]._total = evalFormula(formula, topElements, elementsValue);
            }
        }
        return notes;
    });

    let average_notes = createMemo(() => {
        let average = {
        };
        const notes_memo = notes();
        console.log('memo of memo', notes());
        let keys = {};
        for(let n of Object.values(notes_memo)) {
            for(let el of Object.keys(n)) {

                if(!average[el]) average[el] = 0;
                average[el] += n[el];

                if(!keys[el]) keys[el] = true;
            }
        }
        for(let el of Object.keys(keys)) {
            average[el] /= Object.keys(notes()).length;
            average[el] = Math.round(average[el] * 100) / 100;
        }

        return average;
    });

    let [ordering, setOrdering] = createSignal({col:'_name', asc:1});

    let sorted_names = createMemo(() => {
        if(ordering().col == '_name' || !topElements().includes(ordering().col)) {
            return Object.keys(props.project.pages).sort((a, b) => ordering().asc * (a.localeCompare(b)));
        }
        return Object.keys(props.project.pages).sort((a, b) => ordering().asc * (notes()[a][ordering().col] - notes()[b][ordering().col]));
    });

    return <><table class="table">
    <thead>
      <tr>
        <th scope="col ">
            <a class="clickable" onClick={(e) => {
                e.preventDefault(); 
                setOrdering({
                    col: '_name', 
                    asc: ordering().col == '_name' ? -1*ordering().asc : 1
                });
            }}>
                Student {ordering().col == '_name' ? (ordering().asc == 1 ? '🠉' : '🠋') : null}
            </a>
        </th>
        <For each={topElements()}>
            {(element) => <th scope="col">
                <a class="clickable" onClick={(e) => {
                    e.preventDefault(); 
                    setOrdering({
                        col: element, 
                        asc: ordering().col == element ? -1*ordering().asc : 1
                    });
                }}>
                    {element} {ordering().col == element ? (ordering().asc == 1 ? '🠉' : '🠋') : null}
                </a>
            </th>}
        </For>
        <th scope="col">
            <a onClick={(e) => {
                    e.preventDefault(); 
                    setOrdering({
                        col: '_total', 
                        asc: ordering().col == '_total' ? -1*ordering().asc : 1
                    });
                }}>
                    Total {ordering().col == '_total' ? (ordering().asc == 1 ? '🠉' : '🠋') : null}
            </a>
        </th>
      </tr>
    </thead>
    <tbody>
        <For each={sorted_names()}>
        {(student) => <tr>
            <th scope="row">{student}</th>
            <For each={topElements()}>
                {(element) => <td>{notes()[student][element]}</td>}
            </For>
            <td>{notes()[student]._total}</td>
        </tr>
        }
        </For>
        <tr>
            <td>Average</td>
            <For each={topElements()}>
                {(element) => <td>{average_notes()[element]}</td>}
            </For>
            <td>{average_notes()._total}</td>
        </tr>
    </tbody>
  </table>
  <button onClick={() => {
        let csv = 'Student,Feedback,Total\n';
        for(let student of sorted_names()) {
            csv += '"'+student+ '","';
            for(let el of topElements()) {
                if(el == 'Travail') continue;

                csv += '# '+el+'\n';
                for(let subEl of Object.keys(props.project.pages[student])) {
                    if(subEl.startsWith(el+':') && subEl.endsWith(':comment'))
                    {
                        csv += '  - '+subEl.replace(el+':', '').replace(':comment', '')+': '+props.project.pages[student][subEl].value+'\n';
                    }
                }
            }
            csv += '",'+notes()[student]._total + '\n';
        }
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
        saveAs(blob, "summary.csv");
  }}>Download as CSV</button>
  </>;
}