import { createSignal, onCleanup, onMount } from "solid-js";


export const Loader = () => (<div class="loader"></div>);


export const NavDropdown = (props) => {

    const [showMenu, setShowMenu] = createSignal(false);
    let menuDropdown;

    const handleDropdownClick = (event) => {
        if(event.target == menuDropdown) {
            setShowMenu(!showMenu());
        }
        else {
            setShowMenu(false);
        }
    };

    onMount(() => {
        document.addEventListener('click', handleDropdownClick)
    });
    onCleanup(() => {
        document.removeEventListener('click', handleDropdownClick)
    });

    return <li class={"nav-item dropdown"+(showMenu() ? ' show':'')} >
      <a 
        ref={menuDropdown}
        class="nav-link dropdown-toggle" 
        href="#" 
        id="navbarDropdownMenuLink" 
        role="button" 
        data-bs-toggle="dropdown" 
        aria-expanded="false">
        {props.label}
      </a>
      <ul class={"dropdown-menu"+(showMenu() ? ' show':'')} aria-labelledby="navbarDropdownMenuLink">
        {props.children}
      </ul>
    </li>;
}