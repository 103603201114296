
import { createContext, useContext, onMount } from "solid-js";
import { createStore, produce } from "solid-js/store";
import socket from "./socket";

const AdminContext = createContext();

export function AdminContextProvider(props) {
  const [adminStore, setAdminStore] = createStore({
    users: [],
    results: false,
    resultsFetching: false
  });


  
  onMount(() => {
    console.log('Mounted AdminContext');
    socket.on("user-list", (users) => {
      setAdminStore({users});
    });

    socket.on('results', (results) => {
        setAdminStore({results, resultsFetching: false});
    });

    
    socket.on('user-connected', ({project_id, username}) => {
      if(!project_id) {
        setAdminStore('users', produce((users) => {
          let id = users.findIndex((u) => u.username === username);
          if(id === -1) return;
          users[id].connected = true;
        }));
      }
    });

    socket.on('user-disconnected', ({project_id, username}) => {
      if(!project_id) {
        setAdminStore('users', produce((users) => {
          let id = users.findIndex((u) => u.username === username);
          if(id === -1) return;
          users[id].connected = false;
        }));
      }
    })

  });

  const actions = {
    editUser: (index) => {
      const user = adminStore.users[index];
      const fields = {
        role: user.roleInput,
        project_id: user.project_idInput
      };
      socket.emit('update-user', {id: user.id, fields});
      setAdminStore('users', index, 'editing', false);
    },
    deleteUser: (user_id) => {
      socket.emit('delete-user', user_id);
      setAdminStore('users', (u) => u.filter((user, i) => user.id !== user_id));
    },
    addUser: (fields) => {
      socket.emit('add-user', fields);
    }
  }

  return (
    <AdminContext.Provider value={[adminStore, setAdminStore, actions]}>
      {props.children}
    </AdminContext.Provider>
  );
}

export function useAdminContext() { return useContext(AdminContext); }
