import { useUserContext } from "./UserContext";



export default () => {

    const [userStore, setUserStore, userActions] = useUserContext();
    return <div>
        <h1>Settings</h1>
        <button type="button" class="btn btn-primary"
            onClick={() => {
                localStorage.removeItem('jwt');
                location.reload();
            }}
        >Logout</button>
    </div>
}