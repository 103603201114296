


import { Match, Switch, on, onCleanup, Show, createEffect, onMount, For, createSignal } from "solid-js";
import * as config from './config';
import socket from "./socket";
import { ProjectView } from "./Project";
import { useUserContext } from "./UserContext";

export default (props) => {

  const [projects, setProjects] = createSignal(null);
  const [project, setProject] = createSignal({});

  const [userStore, setUserStore, userActions] = useUserContext();

  onMount(() => {
    socket.emit('list-projects');

    socket.on('project-list', (data) => {
      setProjects(data);
    });
    socket.on('new-project', (data) => {
      setProjects([...projects(), data]);
    });
    socket.on('opened-project', (data) => {
      if(data.id === project().id) {
        setProject({...project(), opening: false, opened: true});
      }
    });
    socket.on('deleted-project', (project_id) => {
      setProjects(projects().filter((p) => p.id !== project_id));
      if(project().id == project_id) {
        setProject({});
      }
      userActions.setPage('/');
    });
  });

  createEffect(() => {
    let m = userStore.page.match(/^\/projects\/(\d+)/);
    if(m && projects() && !project().opening && !project().opened) {
      setProject((project) => {
        if(project.opening || project.opened) return project;
        //console.log('opening project', project);
        socket.emit('open-project', {id: parseInt(m[1])});
        return {...project, opening: true, id: parseInt(m[1])}
      });
    }
    if(userStore.page == '/') {
      setProject({});
    }
  });

  return <div>
    <Show when={project().opening || project().opened}>
        <ProjectView project_id={project().id} />
    </Show>
    <Show when={!project().opened && projects() !== null}>
      <button type="button" style='margin-top:20px' class="btn btn-primary"
        onClick={() => {
          const name = prompt("name", "");
          if(!name) return;
          socket.emit('create-project', { name });
        }}>Add Project</button>

    <For each={projects()}>
      {(project) => <div class="card" style="width: 18rem; margin-top:20px">
  <div class="card-body">
    <h5 class="card-title">{project.name} <small class="text-muted">#{project.id}</small></h5>
    <p class="card-text">{project.description}</p>
    <button 
          type="button"
          onclick={() => {
            userActions.setPage('/projects/' + project.id);
            setProject((project) => {
              if(project.opening || project.opened) return project;

              //console.log('opening project', project);
              socket.emit('open-project', {id: project.id});
              return {...project, opening: true, id: project.id}
            });
          }}
          class='btn btn-info'
          >Open</button>
  </div>
</div>}
    </For>
    </Show>
  </div>
}



