
import { Match, Switch, on, onCleanup, Show, splitProps, onMount, For, createSignal, createMemo, createEffect } from "solid-js";
import * as config from './config';
import socket from "./socket";
import { createStore } from "solid-js/store";
import { useUserContext } from "./UserContext";
import { NavDropdown } from "./Components";
import ProjectSummary from "./ProjectSummary";
import ProjectSettings from "./ProjectSettings";


const Cell = (props) => {

  const [editing_comment, setEditing_comment] = createSignal(false);
  const [editing_note, setEditing_note] = createSignal(false);

  let inputRef_comment;
  let overlayRef_comment;
  let inputRef_note;
  let overlayRef_note;

  const classes_comment = createMemo(() => {
    return {
      'locked': props.cell_comment.lockedBy || !props.cell_comment.editable,
      'editing': editing_comment() && props.cell_comment.editable,
    }
  });
  const classes_note = createMemo(() => {
    return {
      'locked': props.cell_note.lockedBy || !props.cell_note.editable,
      'editing': editing_note() && props.cell_note.editable,
    }
  });

  const inputId = createMemo(() => {
    return `input-${props.project_id}-${props.page}-${props.key}`;
  });

  const cell_comment = createMemo(() => {
    return {
      project_id: props.project_id,
      page: props.page,
      key: props.key_comment,
    }
  });
  const cell_note = createMemo(() => {
    return {
      project_id: props.project_id,
      page: props.page,
      key: props.key_note,
    }
  });


  const handleClick = (event) => {
    if(event.target == inputRef_comment) {
      return;
    }
    if(event.target == inputRef_note) {
      return;
    }

    if(event.target == overlayRef_comment) {
      if(props.lockedBy) {
        return;
      }
      props.lock(cell_comment());
      setEditing_comment(true);
      setTimeout(() => {
        if(inputRef_comment)
          inputRef_comment.focus();
      }, 100);
    }
    else {
      if(editing_comment() && props.cell_comment.editable) {
        props.unlock(cell_comment());
        setEditing_comment(false);
      }
    }

    if(event.target == overlayRef_note) {
      if(props.lockedBy) {
        return;
      }
      props.lock(cell_note());
      setEditing_note(true);
      setTimeout(() => {
        if(inputRef_note)
          inputRef_note.focus();
      }, 100);
    }
    else {
      if(editing_note() && props.cell_note.editable) {
        props.unlock(cell_note());
        setEditing_note(false);
      }
    }
  };


  onMount(() => {
      document.addEventListener('click', handleClick)
  });
  onCleanup(() => {
      if(editing_comment()) 
      {
        props.unlock(cell_comment());
      }
      if(editing_note()) 
      {
        props.unlock(cell_note());
      }
      document.removeEventListener('click', handleClick)
  });


  return <div class='row cell'
    >
    <label for={inputId()} class="col-sm-2 col-form-label">
      {props.label} 
    </label>
    <div
        classList={{...classes_note(), 'col-sm-2': true, 'position-relative': true}}>
        <Show when={editing_note() && props.cell_note.editable} fallback={
          <input 
            ref={overlayRef_note}  
            readOnly={true} 
            disabled={props.cell_note.lockedBy && !editing_note()} 
            class="form-control" 
            type="number"
            value={props.cell_note.value}
            />}>
          <input
            ref={inputRef_note} 
            readOnly={!editing_note() || !props.cell_note.editable} 
            disabled={props.cell_note.lockedBy && !editing_note()} 
            class="form-control" 
            type="number"
            value={props.cell_note.value}
            onInput={(e) => {
              props.onSave(props.project_id, props.page, props.key_note, inputRef_note.value);
            }}
            />
        </Show>
        <Show when={props.cell_note.lockedBy && !editing_note()}>
          <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
            {props.cell_note.lockedBy}
            <span class="visually-hidden">locked by {props.cell_note.lockedBy}</span>
          </span>
        </Show>  
    </div>
    <div
        classList={{...classes_comment(), 'col-sm-8': true, 'position-relative': true}}>
        <Show when={editing_comment() && props.cell_comment.editable} fallback={
          <textarea 
            id={inputId()}
            ref={overlayRef_comment}
            readOnly={true} 
            disabled={props.cell_comment.lockedBy && !editing_comment()} 
            rows={props.cell_comment.value.split('\n').length}
            class="form-control">
              {props.cell_comment.value}
            </textarea>}>
            <textarea 
            id={inputId()}
            ref={inputRef_comment}
            readOnly={!editing_comment() || !props.cell_comment.editable} 
            disabled={props.cell_comment.lockedBy && !editing_comment()} 
            rows={props.cell_comment.value.split('\n').length}
            onInput={(e) => {
              props.onSave(props.project_id, props.page, props.key_comment, inputRef_comment.value);
            }}
            class="form-control">
              {props.cell_comment.value}
            </textarea>
          </Show>
          <Show when={props.cell_comment.lockedBy && !editing_comment()}>
            <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
              {props.cell_comment.lockedBy}
              <span class="visually-hidden">locked by {props.cell_comment.lockedBy}</span>
            </span>
          </Show>  
      </div>
    </div>
}

const TemplatedForm = (props) => {

  const parsed = {
    summary: {
      note: '2{Autonomie} + 1{Travail}',
    },
    student: [
      {
        type: 'text',
        label: 'Autonomie',
      },
      {
        type: 'text',
        label: 'Travail',
      },
    ],
  }

  return <>
    <For each={parsed.student}>
      {(field) => <div>
        <Cell {...props} key={field.label} />
      </div>}
    </For>
  </>
}

export const ProjectView = (props) => {

  const [currentPage, setCurrentPage] = createSignal('Summary');
  const [userStore, setUserStore, userActions] = useUserContext();
  const [sharingLink, setSharingLink] = createSignal(null);

  const project = createMemo(() => {    
    return userStore.openedProject[props.project_id];
  });

  createEffect(() => {
    if(!project()) return;

    const pages = Object.keys(project().pages);
    console.log('project pages update', pages);

    let m = userStore.page.match(/^\/projects\/(\d+)\/(.+)$/);
    if(m && parseInt(m[1]) == project().id && (
      pages.includes(m[2]) || m[2] == 'Settings'
    )) 
    { 
        setCurrentPage(m[2]);
        if(pages.includes(m[2])) {
          userActions.setLastSelectedStudent(m[2]);
        }
    } else {
      userActions.setPage('/projects/' + project().id + '/Summary')
    }
  });


  const onSave = (project_id, page, key, value) => {
    console.log('onSave', project_id, page, key, value);
    socket.emit('project-update-cell', {
      project_id,
      page,
      key,
      value
    })
  }
  const unlock = ({project_id, page, key}) => {
    console.log('unlock', project_id, page, key);
    setUserStore('openedProject', project_id, 'pages', page, key, 'editable', false);
    socket.emit('project-unlock-cell', {
      project_id,
      page,
      key,
    })
  }
  const lock = ({project_id, page, key}) => {
    console.log('lock', project_id, page, key);
    socket.emit('project-lock-cell', {
      project_id,
      page,
      key,
    })
  }


  return <div>
    <Show when={project()} fallback={<div>Loading...</div>}>
    <button style='display:none' type="button" class="btn btn-primary"
        onclick={() => {
          socket.emit('add-project-cell', {
            project_id: project().id,
            key: prompt("key", "")
          });
        }}>Add Cell</button>
        <div class="position-relative">
          <h1>{project().name}</h1>
          <div class="position-absolute top-0 end-0 translate-middle">
            <For each={project().connected}>{
              (username) => <span class="ms-2 badge rounded-pill bg-danger">
                {username}
              </span>
            }</For>
          </div>
        </div>
        <ul class="nav nav-tabs position-relative">
          <li class="nav-item">
            <a 
              onClick={() => {
                userActions.setPage('/projects/' + project.id + '/Summary');
                setCurrentPage('Summary');
              }} classList={{"nav-link":true, active: currentPage()=='Summary'}} aria-current="page" href="#">
                Summary
            </a>
          </li>
          <Show when={userStore.lastSelectedStudent && project().pages[userStore.lastSelectedStudent]}>
            <li class="nav-item">
              <a 
                classList={{"nav-link":true, active: currentPage()==userStore.lastSelectedStudent}}
                onClick={() => {
                  userActions.setPage('/projects/' + project().id + '/' + userStore.lastSelectedStudent);
                  setCurrentPage(userStore.lastSelectedStudent);
                }} aria-current="page" href="#">
                  {userStore.lastSelectedStudent}
                  {userStore.showAdvancedActions ? <span onClick={(e) => {
                    const name = prompt("New name", userStore.lastSelectedStudent);
                    if(!name) return;

                    socket.emit('project-rename-page', {
                      project_id: project().id,
                      oldName: userStore.lastSelectedStudent,
                      newName: name,
                    });
                  }} class="badge bg-secondary page-edit">
                    edit
                  </span>
                  : null}
                </a>
            </li>
          </Show>
          <NavDropdown label='Students'>
            <For each={Object.keys(project().pages)}>
              {(pageName) => <li><a 
                class="dropdown-item"
                href='#'
                onClick={() => {
                  userActions.setPage('/projects/' + project().id + '/' + pageName);
                  userActions.setLastSelectedStudent(pageName);
                  setCurrentPage(pageName);
                }}
                >
                {pageName}
              </a></li>}
            </For>
          </NavDropdown>
          <ul class="nav position-absolute end-0">
          <Show when={project().owner == userStore.id} fallback={
            <li class="nav-item">
            <a 
              onclick={() => {
                if(!confirm("Exit Project ?")) return;
                
                socket.emit('exit-project', project().id);
                userActions.setPage('/');
              }} class="nav-link" href="#">
                Exit Project
              </a>
            </li>
          }>
          {
            userStore.showAdvancedActions ?
            <li class="nav-item">
            <a 
              onclick={() => {
                const name = prompt("Page name", "");
                if(!name) return;

                socket.emit('project-add-page', {
                  project_id: project().id,
                  name
                });
              }} class="nav-link" href="#">
                Add Student
              </a>
          </li>
          :null}
          <li class="nav-item">
            <Show when={project().sharingLink} fallback={<a 
              onclick={() => {
                socket.emit('create-join-link', project().id);
              }} class="nav-link" href="#">
                Invite Members
              </a>}>
                <span class="nav-link">Invite Members: <input type="text" readOnly={true} value={location.origin+'/join?token='+project().sharingLink} /></span>
              </Show>
          </li>
          <li class="nav-item">
            <a 
              onClick={() => {
                userActions.setPage('/projects/' + project().id + '/Settings');
                setCurrentPage('Settings');
              }} classList={{"nav-link":true, active: currentPage()=='Settings'}} aria-current="page" href="#">
                Settings
            </a>
          </li>
          </Show>
          </ul>
        </ul>
        <Show when={currentPage() == 'Summary'}>
          <ProjectSummary project={project()} />
        </Show>
        <Show when={currentPage() == 'Settings'}>
          <ProjectSettings project={project()} />
        </Show>
        <Show when={project().pages[currentPage()]}>
        {(page) => <div>
          <h1>{page}</h1>
          <For each={project().student_template}>
            {(section) => <>
              <h2>{section.name}</h2>
              <For each={section.cells}>
                {(element) => <Switch>
                  <Match when={element.type === 'input-text'}>
                  <Cell 
                      onSave={onSave} 
                      unlock={unlock} 
                      lock={lock} 
                      project_id={project().id} 
                      page={currentPage()} 
                      key_comment={element.key+':comment'} 
                      key_note={element.key+':note'} 
                      label={element.label}
                      cell_comment={page()[element.key+':comment']}
                      cell_note={page()[element.key+':note']}
                      />
                  </Match>
                </Switch>
                }
              </For>
            </>
            }
          </For>
        </div>}
      </Show>
    </Show>
  </div>
}


/* 

<For each={Object.keys(project().pages[page])}>
{(key) => <div>
  <Cell 
      onSave={onSave} 
      unlock={unlock} 
      lock={lock} 
      project_id={project().id} 
      page={page} 
      key={key} 
      label={project().pages[page][key].label}
      value={project().pages[page][key].value} 
      lockedBy={project().pages[page][key].lockedBy}
      editable={project().pages[page][key].editable}
      />
</div>}
</For>

*/
