import { createResource, createSignal, on, onCleanup, onMount, Show } from "solid-js";

import ProjectList from "./ProjectList";

import Admin, { AdminMenu } from "./Admin";

import socket, { SocketStatus } from "./socket";
import { useUserContext } from "./UserContext";
import { Loader, NavDropdown } from "./Components";
import UserSettings from "./UserSettings";

const JoinProject = () => {
  const [userStore, setUserStore, userActions] = useUserContext();
  const [joined, setJoined] = createSignal(false);

  onMount(() => {
    // get the url params
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    socket.emit('join-project', token);
    socket.on('joined-project', (data) => {
      setJoined(true);
      userActions.setPage('/projects/' + data.id);
    });
  });
  return <Show when={joined()} fallback={<h1>Joining...</h1>}>
    <h1>Joined</h1>
  </Show> 
}

export default function App() {

  const [userStore, setUserStore, userActions] = useUserContext();

  const [log, setLog] = createSignal('');

  onMount(() => {
    socket.on('log', (data) => {
      setLog(log() + '\n' + data);
    });
    
    socket.on('project-error', (data) => {
      alert(data.message);
    });
    userActions.setPage(decodeURIComponent(window.location.pathname), {keepSearch: true});
  });

  return (
    <>
    <div class="container">
  <nav class="navbar navbar-expand-lg sticky-top navbar-light bg-light">
    <div class="container-fluid">
    <a 
      onclick={() => { userActions.setPage('/'); }}
      class="navbar-brand" href="#">GridEval</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a 
            onclick={() => { userActions.setPage('/'); }}
            class="nav-link active" 
            aria-current="page" href="#">Home</a>
        </li>
        <NavDropdown label={userStore.username || 'Non connecté'}>
          <li><a
              onclick={() => { userActions.setPage('/user-settings'); }}
              class="dropdown-item" href="#">Settings</a></li>
        </NavDropdown>
        <AdminMenu />
      </ul>
    </div>
    </div>
  </nav>
      <Show when={!userStore.username && userStore.loadingUser}>
        <Loader />
      </Show>
      <Show when={!userStore.username && !userStore.loadingUser}>
        <h1>Non connecté</h1>
        <button type="button" class="btn btn-primary"
          onClick={() => {
            localStorage.setItem('next', location.href);
            location.href = '/login';
          }}>
          Se connecter
        </button>
      </Show>

      <Show when={userStore.username}>
        <Show when={userStore.role === 'admin'}>
          <Admin />
        </Show>
        <Show when={userStore.page === '/' || userStore.page.startsWith('/projects')}>
          <ProjectList />
        </Show>
        <Show when={userStore.page === '/user-settings'}>
          <UserSettings />
        </Show>
        <Show when={userStore.page === '/join'}>
          <JoinProject />
        </Show>
      
        <br/>
        <pre>
          {log()}
        </pre>
      </Show>
      </div>
      <SocketStatus />
    </>
  );
}