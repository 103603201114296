

import { createResource, createSignal, createMemo, onCleanup, onMount, Show, For, Switch } from "solid-js";

import { useAdminContext } from "./AdminContext";


export default (props) => {

    const [adminStore, setAdminStore, {
        editUser,
        deleteUser,
        addUser
    }] = useAdminContext();
    
    //console.log(adminStore, setAdminStore);
    return <>
    <div><button
            type="button" 
            className="btn btn btn-primary btn-sm"  
            onClick={() => {
        const username = prompt("username", "");
        addUser({username, role: 'student'});
    }}>Add User</button></div>
    <table class="table table-striped  table-hover">
    <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Username</th>
        <th scope="col">Role</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
    <For each={adminStore.users}>
        {(user, index) => <tr>
            <td scope="row">{user.id}</td>
            <td>{user.connected ? <span class="connected-badge"></span> : <span class="disconnected-badge"></span>}{user.username}</td>
            <td>
                <Show when={user.editing} fallback={user.role}>
                    <input  class="form-control"
                        value={user.roleInput} 
                        onInput={(e) => setAdminStore('users', index(), {roleInput: e.target.value})
                    }/>
                </Show>
            </td>
            <td>
                <Show when={user.editing}>
                    <button
                        type="button" 
                        className="btn btn btn-outline-primary btn-sm" 
                        onClick={() => editUser(index())}>save</button>
                </Show>
                <Show when={!user.editing}>
                    <button 
                        type="button" 
                        className="btn btn btn-outline-info btn-sm" 
                        onClick={() => setAdminStore('users', index(), {    
                        editing: true, 
                        roleInput: user.role,
                        })}>edit</button>
                </Show>
                {' '}
                <button
                    type="button" 
                    className="btn btn btn-outline-danger btn-sm" 
                    onClick={() => {
                if(confirm('Confirm deletion')) deleteUser(user.id)}}>delete</button></td>
        </tr>
        }
    </For>
    </tbody>
  </table>
  </>;
}