import socketIO from "socket.io-client";
import { onMount, Show, createSignal } from "solid-js";

const socket = socketIO({
  transports: ["websocket", "polling"] // use WebSocket first, if available
});


export default socket;


export const SocketStatus = () => {
  const [status, setStatus] = createSignal('disconnected');

  onMount(() => {
    socket.on('connect', () => {
      console.log('connected');
      setStatus('connected');
    });
    socket.on('disconnect', () => {
      setStatus('disconnected');
      console.log('disconnect');
    });
  });

  return <Show when={status() == 'disconnected'}><div class="socket-status">
    <div class="alert alert-danger" role="alert">

      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
          Connexion au serveur perdue...
    </div>
    </div>
  </Show>
}