
import socket from "./socket";
import { useUserContext } from "./UserContext";


export default (props) => {
    
    const [userStore, setUserStore, userActions] = useUserContext();

    return <div style="margin-top:20px">
        <form>
            <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-2 col-form-label">
                    Delete the project
                </label>
                <div class="col-sm-10">
                <button 
                    type="button" 
                    class="btn btn-danger"
                    onClick={() => {
                        if(!confirm("Delete the project?")) return;
                        socket.emit('delete-project', props.project.id);
                    }}>
                    Delete Project
                </button>
                </div>
            </div>
            <div class="row mb-3">
                <label for="inputEmail3" class="col-sm-2 col-form-label">
                    Advanced actions:
                </label>
                <div class="col-sm-10">
                <button 
                    type="button" 
                    class="btn btn-info"
                    onClick={() => {
                        userActions.toggleShowAdvancedActions();
                    }}>
                    {userStore.showAdvancedActions ? 'Hide' : 'Show'}
                </button>
                </div>
            </div>
        </form>

        
    </div>
}