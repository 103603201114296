import { render } from 'solid-js/web';
import App from './App';
import { AdminContextProvider } from "./AdminContext";
import { UserContextProvider } from "./UserContext";

const app = document.getElementById('app');


if (app) {
  render(() => <div>
  <UserContextProvider>
  <AdminContextProvider>
    <App />
  </AdminContextProvider>
  </UserContextProvider>
  
  <footer class="footer" style={{display:'none'}}>
    <div class="container">
      <span class="text-muted"><a href="http://bramas.fr">bramas.fr</a></span>{' | '}
      <span class="text-muted"><a href="#" onclick={() => {}}>Se déconnecter</a></span>
    </div>
  </footer>
</div>, app);
}